import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import { useAnalytics } from "../hooks"

import {
  AgendaItem,
  CourseBookingCourse,
  CourseForm,
  Header,
  ImageGallery,
  Layout,
  OnlineBooking,
  PrimaryBooking,
  RequestNearYou,
  Review,
  RichText,
  SEO,
  ScrollToBookings,
  SectionFaqs,
  SectionLogos,
  TutorCard,
  SocialMediaAdvert,
} from "../components"
import {
  checkoutSlug,
  courseSlug,
  filterCourseBookings,
  jsonLdDataCourse,
} from "../utilities"
import { courseType, courseBookingType } from "../types"

interface Props {
  data: {
    contentfulCourse: courseType
  } & {
    contentfulPageSectionsLogos: {
      contentful_id: string
      groups: Array<{
        contentful_id: string
        heading: string
        logos: Array<{
          gatsbyImageData: IGatsbyImageData
          title: string
        }>
      }>
    }
  } & {
    allContentfulCourseBookings: {
      edges: Array<{
        node: courseBookingType
      }>
    }
  }
}

export default ({ data }: Props) => {
  const analytics = useAnalytics()
  analytics.track(["User", "Page View"], {
    page: courseSlug(
      data.contentfulCourse.topic.slug,
      data.contentfulCourse.slug
    ),
  })

  const [primaryBooking, setPrimaryBooking] =
    React.useState<courseBookingType>()
  const course = data.contentfulCourse
  const futureBookings = filterCourseBookings({
    bookings: data.allContentfulCourseBookings.edges,
    courseId: course.contentful_id,
  })
  const url =
    process.env.GATSBY_SITE_URL +
    courseSlug(data.contentfulCourse.topic.slug, data.contentfulCourse.slug)

  React.useEffect(() => {
    const updatePrimaryBooking = () => {
      if (location.href) {
        const locationArray = location.href.split("?booking=")
        const requestQuery = locationArray[locationArray.length - 1]
        const filteredBooking = futureBookings.find(
          (booking) => booking.node.contentful_id === requestQuery
        )
        setPrimaryBooking(filteredBooking?.node)
      }
    }
    updatePrimaryBooking()
  }, [])

  return (
    <Layout>
      <SEO
        title={data.contentfulCourse.seo.title}
        description={data.contentfulCourse.seo.description.description}
        image={data.contentfulCourse.seo.socialImage?.file?.url}
        url={url}
      />
      <Helmet>
        <script type="application/ld+json">
          {jsonLdDataCourse({
            courseName: course.name,
            courseUrl: url,
            tutorName: course.tutors[0].name,
          })}
        </script>
      </Helmet>
      <Header
        heading={course.name}
        headerYouTubeVideo={course.youtubeVideo}
        variant="course"
      >
        <section className="facts">
          <span className="fact">
            <b>Skill level:</b> {course.skillLevel}
          </span>
          <span className="fact">
            <b>CPD hours:</b> {course.cpdTime}
          </span>
        </section>
        {course.partnerLogo && (
          <section className="acuphys">
            <b>Brought to you by:</b>
            <GatsbyImage
              className="acuphysLogo"
              image={course.partnerLogo.gatsbyImageData}
              alt={course.partnerLogo.title}
            />
          </section>
        )}
      </Header>
      <main className="backgroundGreyLightSuper">
        <section className="wrapper courseWrapper">
          <section className="courseContent">
            <div className="details">
              <div className="content courseContentSection">
                <h2 className="courseContentSectionHeading">Course details</h2>
                <RichText tickBullets content={course.introduction} />
              </div>
              {course.form && (
                <CourseForm
                  heading={course.form.heading}
                  description={course.form.description}
                  buttonText={course.form.buttonText}
                  buttonUrl={course.form.buttonUrl}
                  variant="courseBody"
                />
              )}
              <SectionLogos
                groups={data.contentfulPageSectionsLogos.groups}
                variant="multiRow"
              />
              <SectionFaqs
                __typename="ContentfulPageSectionsFaQs"
                contentful_id=""
                variant="course"
                faQs={data.contentfulCourse.accordions}
                backgroundColour="Light Grey"
              />
            </div>
            {course.imageGallery && (
              <ImageGallery
                name={course.imageGallery.name}
                images={course.imageGallery.images}
              />
            )}
            {course.tutors && course.tutors.length > 0 && (
              <div className="courseContentSection">
                <h2 className="courseContentSectionHeading">
                  Meet your tutor{course.tutors.length > 1 ? "s" : ""}
                </h2>
                {course.tutors.map((tutor) => (
                  <TutorCard
                    key={tutor.contentful_id}
                    contentful_id={tutor.contentful_id}
                    name={tutor.name}
                    nameShort={tutor.nameShort}
                    slug={tutor.slug}
                    photo={tutor.photo}
                    summary={tutor.summary}
                    twitterProfile={tutor.twitterProfile}
                    linkedInProfile={tutor.linkedInProfile}
                    instagramProfile={tutor.instagramProfile}
                    qualifications={tutor.qualifications}
                    achievements={tutor.achievements}
                    about={tutor.about}
                    seo={course.seo}
                    variant="course"
                  />
                ))}
              </div>
            )}
            {course.agenda && course.agenda.length > 0 && (
              <div
                className={`courseContentSection agenda${
                  course.onlineOnly ? " onlineOnly" : ""
                }`}
              >
                <h2 className="courseContentSectionHeading">Agenda</h2>
                {course.agenda.map((event) => (
                  <AgendaItem
                    key={event.contentful_id}
                    contentful_id={event.contentful_id}
                    type={event.type}
                    name={event.name}
                    details={event.details}
                    video={event.video}
                    videoCover={event.videoCover}
                  />
                ))}
              </div>
            )}
            {!course.onlineOnly && futureBookings.length > 0 ? (
              <div className="courseContentSection bookings" id="bookings">
                <section className="heading">
                  <h2>Course bookings</h2>
                  <Link to="/contact/">Request this course near you</Link>
                </section>
                {futureBookings.map(({ node }) => (
                  <CourseBookingCourse
                    key={node.contentful_id}
                    bookingUrl={checkoutSlug(
                      course.contentful_id,
                      node.contentful_id
                    )}
                    booking={node}
                    customButtonText={course?.customButtonText}
                    hideCoursePrice={course.hideCoursePrice}
                  />
                ))}
              </div>
            ) : !course.onlineOnly ? (
              <div className="courseContentSection bookings" id="bookings">
                <section className="heading">
                  <h2>Course bookings</h2>
                </section>
                <Link className="booking noBookings" to="/contact/">
                  <span>
                    No bookings scheduled,{" "}
                    <span className="noBookingsLink">
                      request this course to be held near you
                    </span>
                  </span>
                </Link>
              </div>
            ) : (
              ""
            )}
          </section>
          <aside className="courseSidebar">
            {primaryBooking ? (
              <PrimaryBooking
                booking={primaryBooking}
                customButtonText={course?.customButtonText}
                bookingUrl={checkoutSlug(
                  course.contentful_id,
                  primaryBooking.contentful_id
                )}
              />
            ) : course.onlineOnly ? (
              <OnlineBooking
                priceValue={course.thinkificTraining.price}
                priceCurrency={course.thinkificTraining.courseCurrency}
                discount={course.thinkificTraining.discount}
                link={course.thinkificTraining.courseUrl}
                customButtonText={course?.customButtonText}
                hideCoursePrice={course.hideCoursePrice}
              />
            ) : futureBookings && futureBookings.length > 0 ? (
              <ScrollToBookings />
            ) : (
              <RequestNearYou />
            )}
            {course.form && (
              <CourseForm
                heading={course.form.heading}
                description={course.form.description}
                buttonText={course.form.buttonText}
                buttonUrl={course.form.buttonUrl}
                variant="courseSidebar"
              />
            )}
            {course.featuredReviews &&
              course.featuredReviews.length > 0 &&
              course.featuredReviews.map((review) => (
                <Review
                  key={review.contentful_id}
                  link={review.reviewSourceUrl}
                  source={review.reviewSource}
                  review={review.review.review}
                  summary={review.reviewSummary.reviewSummary}
                  location={review.reviewerLocation}
                  name={review.reviewerName}
                />
              ))}
            <SocialMediaAdvert />
          </aside>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Course($contentful_id: String!) {
    contentfulCourse(contentful_id: { eq: $contentful_id }) {
      contentful_id
      name
      introduction {
        raw
      }
      slug
      onlineOnly
      skillLevel
      cpdTime
      hideCoursePrice
      youtubeVideo
      thinkificTraining {
        price
        discount
        duration
        courseCurrency
        courseUrl
      }
      partnerLogo {
        gatsbyImageData
        title
      }
      topic {
        slug
      }
      form {
        heading
        description {
          raw
        }
        buttonText
        buttonUrl
      }
      accordions {
        content {
          raw
        }
        heading
        contentful_id
      }
      imageGallery {
        images {
          gatsbyImageData
          contentful_id
          title
        }
      }
      agenda {
        details {
          raw
        }
        type
        name
        contentful_id
        video {
          url
          title
          size
          filename
          description
        }
        videoCover {
          url
        }
      }
      featuredReviews {
        review {
          review
        }
        reviewSummary {
          reviewSummary
        }
        reviewerName
        reviewerLocation
        reviewSource
        reviewSourceUrl
        contentful_id
      }
      seo {
        socialImage {
          file {
            url
          }
        }
        title
        description {
          description
        }
      }
      tutors {
        contentful_id
        qualifications
        achievements
        name
        nameShort
        slug
        about {
          raw
        }
        photo {
          gatsbyImageData
          title
        }
        summary {
          summary
        }
        instagramProfile
        linkedInProfile
        twitterProfile
        seo {
          socialImage {
            file {
              url
            }
          }
          title
          description {
            description
          }
        }
      }
    }
    allContentfulCourseBookings(
      sort: { order: ASC, fields: startDate }
      filter: { contentful_id: { ne: "3bBK7TnvlCI8JsnjF9Ma1m" } }
    ) {
      edges {
        node {
          contentful_id
          addressFull
          addressShort
          endDate
          startDate
          stripePriceId
          priceCurrency
          price
          showVat
          priceDisplayed
          discountedPrice
          locations {
            contentful_id
          }
          courses {
            contentful_id
          }
        }
      }
    }
    contentfulPageSectionsLogos(
      contentful_id: { eq: "56YFWgc3zrzKGZ1Wxe4Pq8" }
    ) {
      contentful_id
      groups {
        contentful_id
        heading
        logos {
          gatsbyImageData
          title
        }
      }
    }
  }
`
